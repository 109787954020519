import React from 'react';
//import Layout from '../components/layout';
import SEO from '../components/seo';
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact';
import Pageheader from '../Utils/pageheader';
import ViewPdf from '../Utils/viewPdf';

const Bramha = () => {
    return (
        <>
            <SEO title="Brahmotsavam" />
            <MDBContainer className="mt-4">
                <h3 className="title font-weight-bolder text-secondary">Brahmotsavam 2022</h3>
                <Pageheader />
                <h4 className="lead text-justify">Brahmotsavam 2022 will happen on 10th June 2022 - 12th June 2022.  </h4>
            </MDBContainer>
            <MDBContainer className="mt-4">
                <h3 className="title font-weight-bolder text-secondary">Brahmotsavam 2022 </h3>
                <Pageheader />
                <MDBRow>
                    <MDBCol className="mx-auto mt-4">

                        <ViewPdf title="Bramhotsavam2022" pdf='https://iowaht.s3.us-east-2.amazonaws.com/pdf/Brahmotsavam+Brochure+2022.pdf' />

                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </>
    );
};

export default Bramha;