import React from 'react';
//import PDFViewer from 'pdf-viewer-reactjs'
const ViewPdf = (props) => {
    return (
        <div className="mx-auto">
            <iframe src={props.pdf} title={props.title} className="w-100 mx-auto" height="450px" />
            {/* <PDFViewer
                navbarOnTop={true}
                document={{
                    url: props.pdf,
                }}
            /> */}
        </div>
    );
};

export default ViewPdf;